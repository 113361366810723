<template>
  <dash-page-new
      :title="$t('TestingReview')"
      :root="$t('Return')"
      :root-location="businessDashLink(`courses/component/${entity.component_id}`)"
      :mobile-title="entity.name"
      :mobile-side-title="$t('Navigation')"
  >

    <template #header_action>
      <test-navigation
          :class="[{'mt-3' : SM}]"
          v-model="selectedQuestion"
          :questions="entity.questions"
          preview
          dropdown
      />
    </template>

    <template #default>
      <v-divider :class="[{'pb-5' : !SM}]" class="mt-3" :style="`border-color : ${wsBACKGROUND}`" />
      <!-- TESTING PROCESS    -->
      <div class="overflow-auto mx-auto pt-6" style="max-width: 780px" >
        <question-viewer
            :question="entity.questions[selectedQuestion] || {}"
            :order-number="selectedQuestion + 1"
            :course-id="entity.course_id"
            :test-id="entity.uuid"
            review
        />

        <test-navigation
            class="mt-6 "
            style="margin-bottom: 100px"
            v-model="selectedQuestion"
            :questions="entity.questions"
            :all-answered="allAnswered"
            preview
        />


      </div>
    </template>

  </dash-page-new>
</template>

<script>
import {mapActions} from "vuex";
import testNavigation from "@/components/pages/westudy/tests/UI/testNavigation";
import questionViewer from "@/components/pages/westudy/tests/UI/questionViewer";
import tests from "@/mixins/tests";

export default {
  name: "DashTestPreview",
  mixins : [tests],
  props : {
    entity : {
      type : Object,
      default() {
        return {
          name : '',
          questions : [],
          duration : 0,
        }
      }
    }
  },
  components : {
    testNavigation,
    questionViewer
  },
  data() {
    return {
      loading : false,
      testResult : {},
      testCompleted : false,
      selectedQuestion : 0,
      displayNavigation : false,
      timeLeft : 0,
      startTimer : false,
    }
  },
  computed : {
    allAnswered() {
      let result = true
      this.entity.questions.forEach((question) => {
        if (!this.QUESTION_HAS_REPLY(question)) { result = false }
      })
      return result
    }
  },
  methods : {
    ...mapActions('courses', [
        'GET_TEST_STUDENT',
    ]),

    //technical
    getNavigationColor(question,i) {
      if ( this.selectedQuestion === i ) { return this.wsDARKER }
      return this.QUESTION_HAS_REPLY(question) ? this.wsATTENTION : ''
    }
  },
}
</script>

<style scoped>

</style>