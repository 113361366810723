<template>
  <studentTestReview
      v-if="reviewMode"
      :entity="testResult"
  />
  <dash-page-new
      v-else
      :title="testResult.test_name"
      :subtitle="$t('TestingProcess')"
      :mobile-title="testResult.test_name"
      icon="mdi-format-list-checks"
      :no-side="!reviewMode"
      :loading="loading"
      :root="testResult.component_name"
      :root-location="businessDashLink(`courses/component/${testResult.component_id}`)"
  >

    <template #default>

      <div class="mx-auto mt-6" style="max-width: 680px; width: 100%">

        <!-- Chips-->
        <div class="d-flex" style="column-gap: 16px">

          <div style="border : 1px solid var(--wsBACKGROUND);width: 100%" class="fill-height  pa-4 wsRoundedSemi">
            <v-icon :color="wsACCENT">mdi-list-box-outline</v-icon>
            <h5 class="wsACCENT mt-1">{{ $t('Points') }}</h5>
            <h3 class="wsACCENT mt-1">{{  testResult.points  }}%</h3>
          </div>



          <div style="border : 1px solid var(--wsBACKGROUND);width: 100%" class="fill-height  pa-4 wsRoundedSemi">
            <v-icon :color="wsACCENT">mdi-check-circle-outline</v-icon>
            <h5 class="wsACCENT mt-1">{{ $t('CorrectAnswers') }}</h5>
            <h3 class="wsACCENT mt-1">{{  testResult.correct  }}</h3>
          </div>

          <div style="border : 1px solid var(--wsBACKGROUND);width: 100%" class="fill-height pa-4 wsRoundedSemi">
            <v-icon :color="wsACCENT">mdi-alert-octagon-outline</v-icon>
            <h5 class="wsACCENT mt-1">{{ $t('WrongAnswers') }}</h5>
            <h3 class="wsACCENT mt-1">{{  testResult.wrong   }}</h3>
          </div>

        </div>

        <div :style="`border: 2px solid ${testResult.status === 'success' ? wsSUCCESS : wsWARNING}`" class="wsRoundedSemi mt-4 pa-4">

          <v-img src="@/assets/illustrations/onboarding_clean.png"  width="70%" class="mx-auto mt-6" />

          <h3 :style="`color : ${testResult.status === 'success' ? wsSUCCESS : wsWARNING}`"
               class="text-center mt-4">
            {{ testResult.status === 'success' ?  $t('testing.finish_success') :  $t('testing.finish_fail') }}
          </h3>

          <h5 v-if="!testResult.test_unlimited"
              style="font-size: 12px"
              class="text-center wsACCENT mt-5">
            <span v-if="testResult.tries_left">
               {{$t('TriesLeft')}} : {{ testResult.tries_left }}
            </span>
            <span v-else>
              {{ $t('testing.no_tries_left') }}
            </span>

          </h5>

          <h5 @click="reviewMode = true" class="wsATTENTION pointer linkHover text-center mt-1">
            {{ $t('ReviewTest') }}
          </h5>

        </div>


        <div class="mt-5 d-flex">
          <ws-link
              :to="businessDashLink('courses/test/' + testResult.test_id)"
              style="width: 100%"
              :disabled="!testResult.test_unlimited && testResult.tries_left < 1"
          >
            <div style="border-radius: 8px 0 0 8px; border : 1px solid var(--wsBACKGROUND); width: 100%"
                 class="d-flex pa-4"
                 v-ripple="testResult.test_unlimited || testResult.tries_left > 0"
            >

              <v-icon class="mr-4" :color="!testResult.test_unlimited && testResult.tries_left < 1 ? wsBACKGROUND : wsACCENT">mdi-arrow-left</v-icon>
              <div>
                <h4 :class="!testResult.test_unlimited && testResult.tries_left < 1 ? 'wsBACKGROUND' : 'wsACCENT'">{{  $t('TryAgain')  }}</h4>
                <h5 :class="!testResult.test_unlimited && testResult.tries_left < 1 ? 'wsBACKGROUND'  : 'wsDARKLIGHT'">{{  testResult.test_name  }}</h5>
              </div>
            </div>
          </ws-link>

          <ws-link
              :to="businessDashLink(`courses/component/${testResult.component_id}`)"
              style="width: 100%"
          >
            <div style="border-radius: 0 8px 8px 0; width: 100%; background-color: var(--wsATTENTION)"
                 class="d-flex justify-end align-center pa-4 fill-height"
            >
              <div>
               <h4 style="color : var(--wsWHITE)">{{ $t('Next') }}</h4>
              </div>

              <v-icon class="ml-4"
                      :color="wsWHITE">
                mdi-arrow-right
              </v-icon>


            </div>
          </ws-link>


        </div>


      </div>




    </template>

  </dash-page-new>

</template>

<script>
import {mapActions} from "vuex";
import studentTestReview from "@/components/pages/westudy/tests/studentTestReview";
export default {
  name: "studentTestResult",
  props : ['uuid'],
  components : {
    studentTestReview
  },
  data() {
    return {
      loading : true,
      testResult : {},
      reviewMode : false,
      selectedQuestion : 0,
    }
  },
  methods : {
    ...mapActions('tests', [
      'FINISH_TEST'
    ]),


    async initPage(){
      if ( this.uuid ) {
        this.loading = true
        let result = await this.FINISH_TEST(this.uuid)
        this.loading = false

        if (!result) {
          return this.ERROR()
        }

        this.testResult = result
        this.testCompleted = true

        if (this.testResult.has_manual_questions ) {
          this.$router.push(this.businessDashLink(`courses/component/${this.testResult.component_id}`))
        }

      }

    },

  },
  mounted()  {
    this.initPage()
  }
}
</script>

<style scoped>

</style>